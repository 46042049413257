.root {
  position: relative;
  height: 100%;
}

.dynamicSpacer {
  height: 15%;
  width: 100%;
}

.appSpinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("assets/images/backgrounds/landing_page.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  z-index: -1;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  flex: 1;
  padding: 32px;
}

.appName {
  text-align: center;
}

.actions {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: white;
  }
}

.signinBtn {
  width: 100%;
}

.centered {
  text-align: center;
}

.input {
  color: var(--ion-color-light);
}

.footer {
  text-align: center;
  padding: 8px 8px 24px 8px;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 140px;
  max-width: 50%;
}

// The styles here are the same as the ones in src/pages/ExperienceMagellan/styles.scss
// since we are mimicking the root of the Magellan experience.
.magellanExperienceRoot {
  box-sizing: border-box;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  // Slightly different background color than the main experience since what we're rendering
  // with this landing page feels like a dialog in the game.
  background: #fcf6ee;
  position: relative;
}
