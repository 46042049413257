.root {
  position: relative;
  height: 100%;
  box-sizing: border-box;

  /* Without setting height the slides will take up the height of the slide's content */
  ion-slides {
    height: 100%;
  }

  &.alignSlidesBottom {
    ion-slides {
      > :global(.swiper-wrapper) {
        align-items: flex-end;
      }
    }
  }
}

.back {
  position: absolute;
  top: 16px;
  left: 8px;
  display: flex;
  align-items: center;
  font-size: 24px;
  z-index: 2;
  padding: 2px 6px 2px 2px;

  &.safeAreaBound {
    top: calc(var(--ion-safe-area-top) + 16px);  /* iOS 11+ */
  }
}

.slide {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;

  &.pager {
    padding-bottom: 36px; // Allows pager to appear in the padding area and not overlap content
  }
}

.slideContent {
  overflow: hidden auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
