.root {
  padding: 24px;
  padding-bottom: calc(var(--ion-safe-area-bottom) + 24px);
}

.action {
  display: flex;
  align-items: center;
}

.option + .option {
  margin-top: 24px;
}
