.root {
  height: 100%;
  display: flex;
  justify-content: center;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  margin-top: 92px;
  text-align: center;
}

.formContainer {
  margin: 0 8px;
  text-align: left;
  width: 80%;
}

.idLabel {
  margin-left: 10px;
}

// Input and Submit buttons should be same height.

.idInput {
  padding: 0 6px;
  border-radius: 20px;
  height: 46px;
}

.namingRequirementLink {
  text-decoration-color: --var(--ion-color-danger);
  text-decoration: underline;
}
