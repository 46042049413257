html,
body {
  position: relative;
  font-family: "Gilroy", sans-serif;
  // Below fix is no longer needed due to UniWebViewContentInsetAdjustmentBehavior that we set
  // ===== (OUTDATED BUT LEAVING COMMENT HERE) =====
  // Fixing iphone X issue on unity webview, need 100vh and position relative for whatever
  // Similar issue: https://github.com/apache/cordova-plugin-wkwebview-engine/issues/108.
  // ===== (OUTDATED BUT LEAVING COMMENT HERE) =====
  height: 100%;
  width: 100%;
  user-select: none;
  cursor: default;
  margin: 0;
  text-rendering: optimizeSpeed;
  // Prevents on web when we test panning the map from dragging the document.
  overflow: hidden;
  // Allows us to customize the background relative to viewport in any webview
  background: transparent;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

img {
  // Prevent dragging the image
  -webkit-user-drag: none;
}

a {
  text-decoration: none;
  // Prevent dragging the link to expose the capacitor url on standalone
  -webkit-user-drag: none;
}

// The main container for the entire website
:global(#root) {
  height: 100%;
}

:global(input[type="number"]::-webkit-inner-spin-button),
:global(input[type="number"]::-webkit-outer-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}
