$user-profile-overflow-menu-right-offset: 20px;
$user-profile-overflow-menu-width: 20px;

.root {
  padding: 40px var(--c-default-modal-padding);
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.overflowMenuWrapper {
  position: absolute;
  top: 20px;
  right: $user-profile-overflow-menu-right-offset + $user-profile-overflow-menu-width;
  bottom: 0;
  // This is a strip that extends all the way to the bottom of the profile card.
  // In order for position: sticky to work, the browser needs to know when to start making something sticky
  // and for how long to make it stick for. We want it to stick all the way down the profile.
  width: 0px;
  z-index: 1;
}

.overflowMenuCtn {
  position: sticky;
  top: calc(20px + var(--ion-safe-area-top));
}

.profile {
  position: relative;
  text-align: center;
  padding-bottom: 80px;
}

.actions {
  text-align: left;
}

.status {
  display: inline-block;
  margin-top: 8px;
  padding: 4px 6px;
  border-radius: 6px;
}

.pending {
  background: var(--ion-color-medium);
}

.blocked {
  background: var(--ion-color-danger);
}

.isFriend {
  background: var(--ion-color-primary);
}

.userActionParent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;

  > .userActionItem:not(:last-child) {
    margin-right: 16px;
  }
}

.userActionButton {
  --border-radius: 20px;
  border-radius: 20px;
  width: 50px;
  height: 50px;
}

.addFriendButton {
  box-shadow: 0 8px 20px 0 rgba(22, 188, 240, 0.2);
}

.messageButton {
  box-shadow: 0 8px 20px 0 rgba(18, 227, 128, 0.2);
}

.buttonContent {
  display: flex;
}
