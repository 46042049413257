@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 100;
    src: url('./Gilroy-Thin.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 200;
    src: url('./Gilroy-UltraLight.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    src: url('./Gilroy-Light.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src: url('./Gilroy-Regular.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    src: url('./Gilroy-Medium.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    src: url('./Gilroy-SemiBold.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    src: url('./Gilroy-Bold.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    src: url('./Gilroy-ExtraBold.otf');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    src: url('./Gilroy-Black.otf');
}
