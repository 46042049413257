.root {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.logo {
  width: 70px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 40px;
  max-width: 340px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
