.root {
  .content {
    text-align: center;
    padding: 32px 24px 24px;
    height: 100%;
  }
}

.updateButton {
  box-shadow: 0px 10px 20px 0px #16bcf033;
  text-transform: uppercase;
}
