.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.topBar {
  flex: 0 0 auto;
  z-index: 2;
  padding-top: var(--ion-safe-area-top);
  background-color: var(--ion-color-white);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);

  &.hidden {
    box-shadow: none;
  }
}

.content {
  flex: 1;
  min-height: 0;
  z-index: 1;
}
