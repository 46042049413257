.root {
  position: relative;
  text-align: center;
  padding: 36px 24px 24px;
}

.dialogContentWrapper {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.transitionContainer {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.transitioning {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
