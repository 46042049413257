.root {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  contain: layout size style;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  z-index: 0;

  &.hidden {
    display: none;
  }
}

.content {
  background: var(--ion-background-color, #fff);
  color: var(--ion-text-color, #000);
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  font-family: var(--ion-font-family, inherit);
  contain: size style;
}
