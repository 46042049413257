.userReportContent {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid rgba(75, 94, 120, 0.08);
  display: flex;
  align-items: center;
  flex: 1 1 0
}

.textParent {
  display: flex;
  flex-direction: column;
}

.link {
  color: var(--ion-color-primary);
  font-weight: 500;
}