.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  --c-button-border-radius: 20px;
  height: 48px;
}

.resetBtn {
  margin: 9px 9px 9px 0;
  &::part(native) {
    border-radius: 12px;
  }
}

.endAdornment {
  font-size: 13px;
}
