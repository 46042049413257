.root {
  display: flex;
  width: 100%;
  margin-left: 16px;

  .error {
    color: var(--ion-color-danger);
    margin-block-start: 12px;

    a {
      font-weight: inherit;
    }

    p,
    ul {
      margin-left: 8px;
    }

    ul {
      li {
        list-style: none;
      }

      li:before {
        content: "\2022";
        vertical-align: middle;
        padding-right: 8px;
      }
    }
  }
}
