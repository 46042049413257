:global(:root) {
  --c-club-list-column-width: 80px;
  --c-club-list-item-width-v2: 40px;
  --c-chat-viewport-padding: 8px;
  --c-default-modal-padding: 24px;
  --c-button-border-radius: 12px;

  // Chat related variables
  --c-chat-input-height: 42px;
  --c-chat-image-attachment-width: 80%;

  // Google Map Related Variables
  --c-default-google-map-background-color: rgba(229, 227, 223, 1);

  // App Router V2 Layering
  --c-navigation-tabs-z-index: 3;
  --c-app-tab-bar-v2-background-color: #afafb0;
  --c-activity-center-hero-container-height: 270px;
  --c-activity-center-hero-container-blurred-section-height: 82px;
  --c-floating-tab-bar-height: 58px;
  --c-position-bottom-px-of-floating-tab-bar: Max(calc(var(--ion-safe-area-bottom, 0) + 8px), 42px);
}
