.root {
  --background: white;
  background-color: var(--background);

  height: 100%;
  width: 100%;
}

.safeAreaBound {
  // Since the modal-wrapper provides the background color, we want to push the
  // containing ion-page down when we want to respect the safe area for a full screen modal.
  // This way, content (like a absolutely positioned back arrow) can anchor from the padded page and not
  // the entire screen (which runs the risk of bleeding into the status bar).
  padding-top: var(--ion-safe-area-top); /* Status bar height on iOS 11+ */
  box-sizing: border-box;
}
