.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 25px;
}

.link {
  color: var(--ion-color-primary);
  text-decoration: underline;
}

.nonAbuse {
  padding: 0 40px;
}

.submitButton {
  height: 48px;
  width: 100%;
  margin: 0;

  &::part(native) {
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(22, 188, 240, 0.2);
  }
}
