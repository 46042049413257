.root {
}

.interstitial {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: var(--ion-color-white);
  overflow: hidden scroll;
}
