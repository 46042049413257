.root {
  --background: transparent;
  --backdrop-opacity: 0.4;
}

.drawer {
  height: 100%;
}

.drawerContent {
  padding: var(--c-default-modal-padding);
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-sizing: border-box;
  min-height: 100%;
}

.slide {
  padding: 4px 20px 24px 20px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroller {
  height: 100%;
  box-sizing: border-box;
  background: transparent;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
}

.center {
  text-align: center;
}

.stretch {
  align-self: stretch;
}

.flexGrow {
  flex-grow: 1;
}

.reportReasonLabel {
  padding-left: 8px;
}

.reportReasonLongDescription {
  // Supports unordered lists in the reason's long description
  ul {
    padding: 0 0 0 30px;

    li {
      list-style-type: disc;
      padding: 3px 0;
    }
  }
}

.submitLabel {
  display: flex;
  text-align: center;
}

.submitBtn {
  box-shadow: 0 8px 20px 0 rgba(22, 188, 240, 0.2);
  border-radius: var(--c-button-border-radius);
}

.ipViolationImageContainer {
  position: relative;
  text-align: center;
  color: rgba($color: #000000, $alpha: 0.4);
}

.ipViolationImage {
  border-width: 1px;
  border-color: transparent;
  border-radius: 12px;
  background-size: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 154px;
  text-indent: -9999px;
}

.centeredCopyButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20%;
  z-index: 2;
}

.copyButtonText {
  margin-left: 5%;
}

.ipLink {
  text-decoration: underline;
}

.violationImageOverlay {
  position: relative;
}

.violationImageOverlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  border-width: 1px;
  border-color: transparent;
  border-radius: 12px;
}
