.outletRoot {
  // DO NOT PUT transform: translate3d(0, 0, 0) here to fix render quirks! Massive performance
  // degradation on android mobile.
  position: relative;
  height: 100%;
  // Removing overflow: hidden from here since its not needed and affects content inside
  // that are position: sticky. See UserProfileEllipsis. We can also move the ellipsis, but this doesnt
  // seem required to have overflow: hidden here.
}

.backdrop {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 100;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.outletContent {
  height: 100%;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // Apply box-shadow using a psuedo element so that we can transition opacity to prevent
  // re-triggering repaints.
  // https://tobiasahlin.com/blog/how-to-animate-box-shadow/
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0 0 4px 3px rgba(47, 47, 47, 0.27);
    opacity: 0;
    transition: opacity 1s;
  }

  &.isOpen {
    &::before {
      // Show the box-shadow faster on initial open.
      transition: opacity 0.2s 0s;
      opacity: 1;
    }
  }
}
