.root {
  // Forces the error boundary to take up the full height of the parent
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  // Centers the error boundary content so that it appears more like a dialog since it's contained within a "widget"
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorView {
  text-align: center;
  padding: 0 16px;

  &Body {
    // Additional padding on the sides so the text is offset relative to the separator
    padding: 0 16px;
  }
}

.lineSeparator {
  width: 100%;
}

.magellanText {
  color: #3d2e23;
}

.errorButton {
  background: rgb(255, 179, 25);
  background: linear-gradient(90deg, rgba(255, 179, 25, 1) 0%, rgba(245, 205, 145, 1) 100%);
  border: none;
  border-radius: 7px;
  padding: 12px 25px;
  width: 100%;
  transition: opacity 0.1s ease;

  &:active,
  &:focus {
    opacity: 0.5;
  }
}
