.root {
  display: flex;
}

.label {
  margin: auto auto auto 0px;
}

.toggle {
  flex-shrink: 0;
}

ion-toggle {
  --background: #4b5e7818;
  --background-checked: #4b5e7818;
  --handle-background: url("assets/icons/x-for-toggle.svg") #acb6c5 no-repeat center / auto;
  --handle-background-checked: url("assets/icons/checkmark.svg") no-repeat center / auto;
}
