.root {
  position: relative;
  width: 240px;
  height: 74px;
  display: flex;
  align-items: center;
}

.contentContainer {
  display: flex;
  margin: 13px 0 13px 49px;
}

.idContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.ribbon {
  position: absolute;
  top: 0;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-danger);
  border-radius: 0px 0px 5px 5px;
}

.airship {
  color: var(--ion-color-white);
  margin: 0 2px 2px 2px;
}

.avatarContainer {
  display: flex;
  align-items: center;
  width: 48px;
}

.avatarImg {
  width: 100%;
}
