.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.subtitleText {
  color: #2f496dcc;
}

.link {
  color: var(--ion-color-primary);
  text-decoration: underline;
}
