.root {
}

.loading {
  display: grid;
  place-items: center;
  height: 140px;
  width: 100%;
}

.realityChannelRowItem {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 40px;
}

.realityChannelRowItem + .realityChannelRowItem {
  margin-top: 26px;
}

.realityChannelIcon {
  width: 38px;
  height: 38px;
  border-radius: 10px;
  flex: 0 0 auto;
}

.checkmark {
  position: absolute;
  right: 6px;
  bottom: 0;
  top: 0;
  margin: auto;
}

.newIndicator {
  position: absolute;
  right: 4px;
  background: red;
  border-radius: 5px;
  padding: 1px 3px;
}
