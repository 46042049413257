.root {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 100px 16px;
  text-align: center;
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("assets/images/backgrounds/landing_page.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  z-index: -1;
}
