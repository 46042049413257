:global(body).dark {
  // Background color default override
  --ion-background-color: #232323;
  --ion-background-color-rgb: 35, 35, 35;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  // "primary" color override
  --ion-color-primary: #0085ad;
  --ion-color-primary-rgb: 0, 133, 173;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #007598;
  --ion-color-primary-tint: #1a91b5;

  // "Dark" color override
  --ion-color-dark: #ffffff;
  --ion-color-dark-rgb: 255, 255, 255;
  --ion-color-dark-contrast: #2f496d;
  --ion-color-dark-contrast-rgb: 47, 73, 109;
  --ion-color-dark-shade: #e0e0e0;
  --ion-color-dark-tint: #ffffff;

  // "Light" color override
  --ion-color-light: #92949c;
  --ion-color-light-rgb: 146, 148, 156;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #808289;
  --ion-color-light-tint: #9d9fa6;

  // Custom color "white" override
  --ion-color-white: #232323;
  --ion-color-white-rgb: 35, 35, 35;
  --ion-color-white-contrast: #ffffff;
  --ion-color-white-contrast-rgb: 255, 255, 255;
  --ion-color-white-shade: #1f1f1f;
  --ion-color-white-tint: #393939;

  // Color step override
  --ion-color-step-50: #2e2e2e;
  --ion-color-step-100: #393939;
  --ion-color-step-150: #444444;
  --ion-color-step-200: #4f4f4f;
  --ion-color-step-250: #5a5a5a;
  --ion-color-step-300: #656565;
  --ion-color-step-350: #707070;
  --ion-color-step-400: #7b7b7b;
  --ion-color-step-450: #868686;
  --ion-color-step-500: #919191;
  --ion-color-step-550: #9c9c9c;
  --ion-color-step-600: #a7a7a7;
  --ion-color-step-650: #b2b2b2;
  --ion-color-step-700: #bdbdbd;
  --ion-color-step-750: #c8c8c8;
  --ion-color-step-800: #d3d3d3;
  --ion-color-step-850: #dedede;
  --ion-color-step-900: #e9e9e9;
  --ion-color-step-950: #f4f4f4;

  // Disabling for now, but this makes stuff appear different in ion-modals
  :global(ion-modal) {
    //--ion-background-color: var(--ion-color-step-100);
    //--ion-toolbar-background: var(--ion-color-step-150);
    //--ion-toolbar-border-color: var(--ion-color-step-250);
    //  --ion-item-background: var(--ion-color-step-50);
  }

  :global(ion-toggle) {
    --background: var(--ion-color-medium);
  }

  // Makes ion-item backgrounds
  // --ion-item-background: var(--ion-color-step-50);

  // UNKNOWN FOR NOW
  --ion-card-background: #171717;
}
