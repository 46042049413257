.root {
  .content {
    text-align: center;
    padding: 60px 24px 24px;
    height: 100%;
  }
}

.datePickerContainer {
  text-align: left;
}

.label {
  margin-left: 10px;
}

.datePicker {
  text-align: center;
  margin: 4px 0 0;
  border-radius: var(--c-button-border-radius);
  background-color: rgba(75, 94, 120, 0.08);
  color: var(--ion-color-dark);
  font-size: 16px;
  font-weight: 700;
}
