.friendContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.addButtonContainer {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  right: 0;
}

.contentContainer {
  display: flex;
  margin: 20px 16px;
}

.idContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.avatarContainer {
  display: flex;
  align-items: center;
  width: 48px;
}

.avatarImg {
  width: 100%;
}

.pendingText {
  margin: 16px;
}

.submitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}
