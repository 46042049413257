.root {
  text-align: left;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}

.headerLabelAndInfo {
  display: flex;
  align-items: center;
}

.label {
  text-transform: uppercase;
  margin: 0 4px;
}

.logoWrapper {
  flex: 0 0 auto;
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  overflow: hidden;
}

.logo {
  // Make sure the image covers the container, maintaining its aspect ratio
  object-fit: cover;

  // Fit the image to the parent container's dimensions.
  width: 100%;
  height: 100%;
}

.itemParent {
  border-radius: 16px;
  border: 1px solid rgba(75, 94, 120, 0.08);
  overflow: hidden;

  > .ionItem {
    padding: 12px;
    --inner-padding-end: 0;
    --padding-start: 0;
    --padding-end: 0;
  }

  > .ionItem + .ionItem {
    border-top: 1px solid rgba(75, 94, 120, 0.08);
  }
}

.end {
  margin: 0;
}

.appItem {
  display: flex;
  align-items: center;
}

.textParent {
  display: flex;
  flex-direction: column;
}

.gameProfilesInfoDialogContent {
  padding: var(--c-default-modal-padding);
  text-align: center;
}

.pipe {
  margin: 0 4px;
}
