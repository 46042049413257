$menu-group-padding-label: 8px;

.label {
  text-transform: uppercase;
  margin: 0 $menu-group-padding-label;

  &.disabled {
    opacity: 0.4;
  }
}

.menuGroup {
  &.connected {
    border-radius: 16px;
    overflow: hidden;
  }
}
