.root {
  padding: 32px 40px;
  text-align: center;
  background: var(--background);
  border-radius: 20px;
}

.button {
  --box-shadow: 0px 10px 20px rgba(22, 188, 240, 0.1);
  border-radius: 20px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.clearButton {
  --ion-color-light-contrast: #acb6c5;
  --box-shadow: 0px 10px 20px rgba(22, 188, 240, 0.1);
  border-radius: 20px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: transparent;
}

.campfireLogo {
  height: 60px;
  width: 60px;
  margin: 0 auto;
  color: var(--ion-color-secondary);
}

.img {
  // Percentage of the entire screen so that the user doesn't have to scroll too much to tap the buttons.
  max-height: 37vh;
}
