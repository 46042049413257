$ui-popper-caret-size: 16px;

.root {
  display: inline-block;
}

.popperRoot {
  position: absolute;

  &[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }

  &[data-popper-placement^="bottom"],
  &[data-popper-placement^="bottom-start"],
  &[data-popper-placement^="bottom-end"] {
    .arrow {
      top: -($ui-popper-caret-size / 2);
    }
  }

  &[data-popper-placement^="top"],
  &[data-popper-placement^="top-start"],
  &[data-popper-placement^="top-end"] {
    .arrow {
      bottom: -($ui-popper-caret-size / 2);
    }
  }

  &[data-popper-placement^="left"],
  &[data-popper-placement^="left-start"],
  &[data-popper-placement^="left-end"] {
    .arrow {
      right: -($ui-popper-caret-size / 2);
    }
  }

  &[data-popper-placement^="right"],
  &[data-popper-placement^="right-start"],
  &[data-popper-placement^="right-end"] {
    .arrow {
      left: -($ui-popper-caret-size / 2);
    }
  }
}

// Literally follow this tutorial:
// https://popper.js.org/docs/v2/tutorial
.arrow {
  position: absolute;
  width: $ui-popper-caret-size;
  height: $ui-popper-caret-size;
  background: inherit;
  visibility: hidden;
  background-color: var(--background-color);

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    position: absolute;
    width: $ui-popper-caret-size;
    height: $ui-popper-caret-size;
    background: inherit;
  }
}

.popperContent {
  background-color: var(--background-color);
}
