.card {
  position: relative;
  width: 235px;
  border-width: 1px;
  border-color: var(--ion-color-white-shade);
  border-style: solid;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: var(--ion-color-white);
  border-radius: 16px;
}

.cardTop {
  position: absolute;
  top: 196px;
}

.cardMiddle {
  position: absolute;
  top: 276px;
  transform: translateX(70px);
}

.cardBottom {
  position: absolute;
  top: 320px;
  transform: translate(-40px, 2px);
}

.campfireBadge {
  width: 112px;
  position: absolute;
  top: 400px;
}

.friendContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.addButtonContainer {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  right: 0;
}

.contentContainer {
  display: flex;
  margin: 12px;
}

.idContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatarImg {
  width: 100%;
}

.pendingText {
  margin: 16px;
}
