.root {
  .content {
    text-align: center;
    padding: 60px 24px 24px;
    height: 100%;
  }

  .googleBtn {
    --border-radius: 60px;
    --background: #ffffff;
    --background-hover: #ffffff;
    --background-activated: #ffffff;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-medium);
    --color: var(--ion-color-dark);
  }

  .facebookBtn {
    --border-radius: 60px;
    --background: #1877f2;
    --background-hover: #3b8ffc;
    --background-activated: #3b8ffc;
  }

  .appleBtn {
    --border-radius: 60px;
    --background: #000000;
    --background-hover: #202020;
    --background-activated: #202020;
  }

  .btnContent {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .googleLoginBtnText {
    font-weight: 700;
    width: 100%;
  }

  .loginBtnText {
    font-weight: 700;
    width: 100%;
  }

  .googleLoginIcon {
    display: inline-block;
    margin: 0px auto;
  }

  .loginIcon {
    margin-left: 0px;
  }

  .termsOfService {
    font-weight: 400;
    white-space: pre-line;
  }

  .termsOfService a {
    font-weight: 600;
    color: var(--ion-color-medium);
    text-decoration: underline;
  }
}
