:global(body).pgo {
  // Background color default override
  --ion-background-color: #76d5b6;
  --ion-background-color-rgb: 118, 213, 182;

  --ion-text-color: #3f4d5a;
  --ion-text-color-rgb: 63, 77, 90;

  --ion-color-step-50: #73ceb1;
  --ion-color-step-100: #71c7ad;
  --ion-color-step-150: #6ec1a8;
  --ion-color-step-200: #6bbaa4;
  --ion-color-step-250: #68b39f;
  --ion-color-step-300: #66ac9a;
  --ion-color-step-350: #63a596;
  --ion-color-step-400: #609f91;
  --ion-color-step-450: #5d988d;
  --ion-color-step-500: #5b9188;
  --ion-color-step-550: #588a83;
  --ion-color-step-600: #55837f;
  --ion-color-step-650: #527d7a;
  --ion-color-step-700: #507676;
  --ion-color-step-750: #4d6f71;
  --ion-color-step-800: #4a686c;
  --ion-color-step-850: #476168;
  --ion-color-step-900: #455b63;
  --ion-color-step-950: #42545f;

  // "primary" color override
  --ion-color-primary: #73ffb1;
  --ion-color-primary-rgb: 115, 255, 177;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #65e09c;
  --ion-color-primary-tint: #81ffb9;

  // "Dark" color override
  --ion-color-dark: #3e4d55;
  --ion-color-dark-rgb: 62, 77, 85;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #37444b;
  --ion-color-dark-tint: #515f66;

  // "Light" color override
  --ion-color-light: #c9fec0;
  --ion-color-light-rgb: 201, 254, 192;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #b1e0a9;
  --ion-color-light-tint: #cefec6;

  // Custom color "white" override
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  // Disabling for now, but this makes stuff appear different in ion-modals
  :global(ion-modal) {
    //--ion-background-color: var(--ion-color-step-100);
    //--ion-toolbar-background: var(--ion-color-step-150);
    //--ion-toolbar-border-color: var(--ion-color-step-250);
    // --ion-item-background: var(--ion-color-step-50);
  }

  :global(ion-toggle) {
    --background: var(--ion-color-medium);
  }

  // Makes ion-item backgrounds
  // --ion-item-background: var(--ion-color-step-50);

  // UNKNOWN FOR NOW
  --ion-card-background: #171717;
}
