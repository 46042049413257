// These are the core color themes we use for ionic components in our app!
// ANYTIME YOU CHANGE A COLOR HERE,
// PLEASE USE: https://ionicframework.com/docs/theming/color-generator
// =====================================
// ADDING A NEW COLOR: Super easy. https://ionicframework.com/docs/theming/colors#new-color-creator.
// Then, use it like <IonText color="newColorName">
:global(:root) {
  --ion-font-family: "Gilroy", sans-serif;

  --ion-color-primary: #16bcf0;
  --ion-color-primary-rgb: 22, 188, 240;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #13a5d3;
  --ion-color-primary-tint: #2dc3f2;

  --ion-color-secondary: #ff731e;
  --ion-color-secondary-rgb: 255, 115, 30;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #e0651a;
  --ion-color-secondary-tint: #ff8135;

  --ion-color-tertiary: #3571c5;
  --ion-color-tertiary-rgb: 53, 113, 197;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2f63ad;
  --ion-color-tertiary-tint: #497fcb;

  --ion-color-success: #12e489;
  --ion-color-success-rgb: 18, 228, 137;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #10c979;
  --ion-color-success-tint: #2ae795;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #ff443d;
  --ion-color-danger-rgb: 255,68,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e03c36;
  --ion-color-danger-tint: #ff5750;

  --ion-color-dark: #2f496d;
  --ion-color-dark-rgb: 47, 73, 109;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #294060;
  --ion-color-dark-tint: #445b7c;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #c9cfd7;
  --ion-color-light-rgb: 201, 207, 215;
  --ion-color-light-contrast: #4b5e78;
  --ion-color-light-contrast-rgb: 75, 94, 120;
  --ion-color-light-shade: #b1b6bd;
  --ion-color-light-tint: #ced4db;

  // Custom colors
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #2f496d;
  --ion-color-white-contrast-rgb: 47, 73, 109;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-blue-grey: #2f496d80;
  --ion-color-blue-grey-rgb: 73, 109, 128;
  --ion-color-blue-grey-contrast: #ffffff;
  --ion-color-blue-grey-contrast-rgb: 255, 255, 255;
  --ion-color-blue-grey-shade: #406071;
  --ion-color-blue-grey-tint: #5b7c8d;

  :global(.ion-color-white) {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
  }

  :global(.ion-color-blue-grey) {
    --ion-color-base: var(--ion-color-blue-grey);
    --ion-color-base-rgb: var(--ion-color-blue-grey-rgb);
    --ion-color-contrast: var(--ion-color-blue-grey-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-grey-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-grey-shade);
    --ion-color-tint: var(--ion-color-blue-grey-tint);
  }

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #2f496d;
  --ion-text-color-rgb: 47, 73, 109;

  --ion-color-step-50: #f5f6f8;
  --ion-color-step-100: #eaedf0;
  --ion-color-step-150: #e0e4e9;
  --ion-color-step-200: #d5dbe2;
  --ion-color-step-250: #cbd2db;
  --ion-color-step-300: #c1c8d3;
  --ion-color-step-350: #b6bfcc;
  --ion-color-step-400: #acb6c5;
  --ion-color-step-450: #a1adbd;
  --ion-color-step-500: #97a4b6;
  --ion-color-step-550: #8d9baf;
  --ion-color-step-600: #8292a7;
  --ion-color-step-650: #7889a0;
  --ion-color-step-700: #6d8099;
  --ion-color-step-750: #637792;
  --ion-color-step-800: #596d8a;
  --ion-color-step-850: #4e6483;
  --ion-color-step-900: #445b7c;
  --ion-color-step-950: #395274;
}
