.addFriendButton {
  box-shadow: 0 8px 20px 0 rgba(22, 188, 240, 0.2);
  border-radius: 20px;
  flex: 1;
}

.allAppDisplay {
  padding: 18px 0;
  overflow: hidden;
}

.checkboxContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  > :last-child {
    margin-left: 8px;
  }
}
