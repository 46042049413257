.toggleGroupRoot {
  border-top: 1px solid rgba(75, 94, 120, 0.08);
}

.toggle {
  padding: 14px 16px;
  line-height: 32px;
  border-bottom: 1px solid rgba(75, 94, 120, 0.08);
}

.radioGroup {
  padding-left: 16px;
}
