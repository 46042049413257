// A mixin to quickly generate table classes for your component.
// When you @include any of the mixins here, you essentially get those classnames to use
// in your component. I will admit, sorta weird that the name is defined from the mixin but this is mainly
// the case if you use the generateDefaultTable mixin which generates default classes for:
// - Table
// - Table Header
// - Table Body
// - Table Row
// - Table Cell
//
// Usage:
// ======
// styles.scss
// .root {
//   @include generateDefaultTable(1fr 1fr);
// }

// index.html
// <div className={styles.root}>
//   <div className={styles.tableHeader} /> // You get this for free
// </div>

// Alternatively, you can also use the building blocks for more control
// styles.scss
// .myHeader {
//   @include generateTableHeader(1fr 1fr);
// }

// index.html
// <div className={styles.root}>
//   <div className={styles.myHeader} />
// </div>
@mixin generateDefaultTable($grid-template-columns, $row-hover-color: false, $row-padding: 8px 0) {
  @include generateTableRoot();

  .tableRow {
    @include generateTableRow($grid-template-columns, $row-hover-color, $row-padding);
  }

  // Since the body has a scroll bar, we add one to the header so that it shifts the content
  // by the same amount. Otherwise, things wont line up exactly.
  .tableHeader {
    @include generateTableHeader($grid-template-columns);
  }

  .tableBody {
    @include generateTableBody();
  }

  .tableCell {
    @include generateTableCell();
  }
}

@mixin generateTableRoot() {
  width: auto;
  display: grid;
  border-collapse: collapse;
}

@mixin generateTableHeader($grid-template-columns) {
  display: grid;
  grid-template-columns: $grid-template-columns;
  overflow-y: scroll;
}

@mixin generateTableBody() {
  overflow-y: scroll;
}

@mixin generateTableRow($grid-template-columns, $row-hover-color: false, $row-padding: 0.5rem 0) {
  display: grid;
  grid-template-columns: $grid-template-columns;
  padding: $row-padding;
  border-radius: 2px;
  min-width: 0;

  @if ($row-hover-color) {
    cursor: pointer;

    &:hover {
      background-color: $row-hover-color;
    }
  }
}

@mixin generateTableCell() {
  text-align: left;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;

  &.clickable {
    cursor: pointer;
  }

  &.leftAligned {
    justify-content: flex-start;
  }

  &.rightAligned {
    text-align: right;
    justify-content: flex-end;
  }

  &.centered {
    justify-content: center;
  }

  &.truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
