$pgo-campfire-layout-drawer-offset-top: 52px;

.root {
  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  contain: size layout style;
  z-index: 0;
  background: linear-gradient(180deg, #4d9ef6 6.85%, #b1d6f6 46.88%);
}

.exitEmbedClickableDiv {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: calc(#{$pgo-campfire-layout-drawer-offset-top} + var(--ion-safe-area-top));
}

.fakeMask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.6;
  z-index: -1;
}

.fakeDrawer {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 0;
  contain: size layout style;
  margin-top: calc(#{$pgo-campfire-layout-drawer-offset-top} + var(--ion-safe-area-top));
}

.content {
  position: relative;
  flex: 1 1 0;
  background-color: var(--ion-color-white);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  // This is the loading BG color for google maps. Since the map is fullscreen
  // this mirrors that while we load the google map.
  background-color: var(--c-default-google-map-background-color);

  // Map and safari for iOS for some reason is overflowing the border radius.
  // Solution is to make it into a stacking context either with z-index or transform: translate3d(0, 0, 0)
  // https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  z-index: 0;
}
