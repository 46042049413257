.root {
  display: flex;
  align-items: center;
  border-radius: var(--c-button-border-radius);
  background-color: rgba(75, 94, 120, 0.08);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.2px;

  input {
    &:global(.native-input) {
      padding: 14px;
    }
  }
}

.label {
  margin-left: 10px;
}

.input {
  flex: 1;
}

.endAdornment {
  margin-left: auto;
  flex: 0 1 auto;
}
