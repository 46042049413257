.continueButtonLink {
  box-shadow: 0 10px 20px rgba(3, 206, 118, 0.2);
  border-radius: var(--c-button-border-radius);
  flex: 1;
  display: flex;
  margin: 4px 2px;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-success);
  height: 2.8em;
}