.root {
  position: relative;
  width: 100%;
  border-width: 1px;
  border-color: var(--ion-color-white-shade);
  border-style: solid;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: var(--ion-color-white);
}

.roundedLarge {
  border-radius: 12px;
}

.roundedSmall {
  border-radius: 8px;
}

.ribbon {
  position: absolute;
  top: 0;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-danger);
  border-radius: 0px 0px 8px 8px;
}

.airship {
  color: var(--ion-color-white);
}
