.root {
  // To show a slight depress
  &:active,
  &.selected {
    opacity: 0.6;
  }

  &.disabled {
    opacity: 0.3;
  }
}
