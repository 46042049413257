.root {
  font-family: "Gilroy", sans-serif;
  font-weight: 400;
  word-break: break-word;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}

// ===== Variants =====
.body1 {
  font-size: 16px;
  letter-spacing: 0.2px;
}

.body2 {
  font-size: 12px;
  letter-spacing: 0.2px;
}

.overline {
  font-size: 16px;
  letter-spacing: 0.1px;
}

.subtitle1 {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.subtitle2 {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.h1 {
  font-size: 48px;
  letter-spacing: -0.1px;
}

.h2 {
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.09px;
}

.h3 {
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 0.2px;
}

.h4 {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.2px;
}

.h5 {
  font-size: 13px;
  line-height: 1.25;
  letter-spacing: 0.2px;
}

.h6 {
  font-size: 11px;
  line-height: 1.25;
  letter-spacing: 0.2px;
}

// ===== Modifiers =====
.weightThin {
  font-weight: 100;
}

.weightUltraLight {
  font-weight: 200;
}

.weightLight {
  font-weight: 300;
}

.weightMedium {
  font-weight: 500;
}

.weightSemiBold {
  font-weight: 600;
}

.weightBold {
  font-weight: 700;
}

.weightExtraBold {
  font-weight: 800;
}

.weightBlack {
  font-weight: 900;
}

// ===== Text Overflow =====
.textOverflow {
  overflow: hidden;
  white-space: nowrap;
}

.textOverflowEllipsis {
  text-overflow: ellipsis;
}

// ===== Text Colors =====
.colorPrimary {
  color: var(--ion-color-primary);
}

.colorSecondary {
  color: var(--ion-color-secondary);
}

.colorTertiary {
  color: var(--ion-color-tertiary);
}

.colorDark {
  color: var(--ion-color-dark);
}

.colorMedium {
  color: var(--ion-color-medium);
}

.colorLight {
  color: var(--ion-color-light);
}

.colorWhite {
  color: var(--ion-color-white);
}

.colorSuccess {
  color: var(--ion-color-success);
}

.colorWarning {
  color: var(--ion-color-warning);
}

.colorDanger {
  color: var(--ion-color-danger);
}
