.root {
  position: relative;
  height: 100%;
}

.dynamicSpacer {
  height: 15%;
  width: 100%;
}

.appSpinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("assets/images/backgrounds/landing_page.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
  z-index: -1;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  flex: 1;
  padding: 32px;
}

.appName {
  text-align: center;
}

.actions {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: white;
  }
}

.signupBtn {
  width: 100%;
}

.signinBtn {
  width: 100%;
  color: var(--ion-color-dark);
}

.centered {
  text-align: center;
}

.input {
  color: var(--ion-color-light);
}

.footer {
  text-align: center;
  padding: 8px 8px 24px 8px;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 140px;
  max-width: 50%;
}

.helpContainer {
  display: flex;
  justify-content: center;
}

.getSupportLink {
  color: var(--ion-color-white);
  text-decoration: underline;
}
