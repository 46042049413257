.root {
  height: 100%;
}

.scroller {
  height: 100%;
  box-sizing: border-box;
  background: transparent;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  // [CAMP-2695] iOS 16 / Safari issue where opening profile more than 1 time sometimes causes the x to be layered
  // incorrectly. Create a new stacking context for this element so things get layered nicely.
  isolation: isolate;
}

.profileWrapper {
  position: relative;
  background-color: var(--ion-color-white);
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
}

.actions {
  text-align: left;
  // Render quirk on menu groups for iOS not being rounded due to react-spring and UINav refactor
  // Probably since we use transform: translateX() and theres some stacking context bug with
  // safari, so works around that.
  transform: translate3d(0, 0, 0);
}

.paneContent {
  height: 100%;
}

.inviteToClubBtn {
  // TODO: See if we can apply this to all buttons in the future. For design review.
  --background-activated-opacity: 1;
}

.buttonGradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.6685049019607843) 77%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  // Needs to beat the .item-native from an ion-item component we use which is default to z-index: 1.
  // So lets just bump it up a little more to be safe.
  z-index: 3;
}

.overscrollBg {
  border-radius: 24px 24px 0px 0px;
  position: absolute;
  // top is defined on the element directly.
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--ion-color-white);
  z-index: 0;
}

.closeBtn {
  height: 60px;
  width: 60px;

  &::part(native) {
    background-color: #e4e7eb;
    height: 60px;
    width: 60px;
    border-radius: 22.5px;
    box-shadow: 0 4px 19px 0 rgba(75, 94, 120, 0.1);
  }
}
