.root {
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
}

.error {
  padding: 16px;
  text-align: center;
}
