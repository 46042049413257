.root {
  display: inline-block;
  // When used in flex containers, this ensures the avatar's size remains constant regardless of its flex siblings
  flex-shrink: 0;

  &.square {
    --border-radius: 35%;
  }
}

.renderText {
  background-color: var(--background);
  --background: var(--ion-color-light-shade);
}

.usingDefaultAvatar {
  background-color: var(--background);
  --background: var(--ion-color-light-shade);
}

.noAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.square {
    border-radius: 35%;
  }
}

.letter {
  font-size: 0.4em;
}

.customAvatarContent {
  --background: var(--ion-color-light-shade);

  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  border-radius: 50%;
  overflow: hidden;

  &.square {
    border-radius: 35%;
  }
}
