$menu-group-padding-start: 16px;
$menu-item-padding: 13px;

.root {
  &.connected {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-bottom: #{$menu-item-padding};
    --inner-padding-top: #{$menu-item-padding};
    --inner-padding-start: #{$menu-group-padding-start};
    --inner-padding-end: 0;
    --border-style: none;
    --background: rgba(75, 94, 120, 0.08);
  }

  &.disconnected {
    --border-radius: 16px;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-bottom: #{$menu-item-padding};
    --inner-padding-top: #{$menu-item-padding};
    --inner-padding-start: #{$menu-group-padding-start};
    --inner-padding-end: 0;
    --border-style: none;
    --background: rgba(75, 94, 120, 0.08);
  }

  &.disabled {
    opacity: 0.4;
  }
}

.menuItemText {
  display: block;
}

.endArrow {
  display: flex;
}
