.root {
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.submitContainer {
  position: fixed;
  // For Android Chrome < 79, since CSS Max() is not supported, ensure we position this somewhere decent.
  bottom: 42px;
  bottom: Max(calc(var(--ion-safe-area-bottom, 0) + 42px), 42px);
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}
