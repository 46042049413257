.root {

}

.settingsOption {
  display: flex;
  align-items: center;
  padding: 0 0 24px 0;
}

.drawer {
  padding: 24px 28px;
}
