.srcImage {
  height: 18px;
  width: 18px;
  border-radius: 4px;
  overflow: hidden;
}

.srcRoot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.feedbackToast {
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    flex: 0 0 auto;
    color: var(--ion-color-white);
  }

  .feedbackToastContent {
    display: flex;
    flex: 1;
  }
}

.justifyContentCenter {
  justify-content: center;
}
