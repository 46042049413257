.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  height: 100%;
  box-sizing: border-box;
  min-height: 0;
  background-color: var(--ion-background-color);

  &.scrollable {
    // Safari bug with transform, border-radius, and overflow: hidden. Safari needs a new stacking context
    // to properly bound the content within the border.
    // https://bugs.webkit.org/show_bug.cgi?id=98538
    transform: translateZ(0);
    overflow: hidden scroll;
  }
}

.backBtn {
  margin-left: 2px;
  display: flex;
}
