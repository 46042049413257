.root {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);

  &.flat {
    box-shadow: none;
  }

  ion-title {
    padding: 0 40px;
  }
}

.toolbar {
  --border-width: 0 !important;
  --background: var(--ion-background-color);
  // When an ion-toolbar is used inside an ion-header, the toolbar receives padding on the top
  // equal to the safe area on mobile devices. Now that we are adding a embedded header,
  // this is no longer the top most element in the view. So the safe area belongs elsewhere.
  &.overrideDefaultSafeArea {
    padding-top: 0;
  }
}
