// Styles given to UIModal for the floating type
.floating {
  height: auto;
  left: 16px;
  right: 16px;
  bottom: 0;
  background-color: var(--ion-color-white);
  border-radius: 20px;
}

// Styles given to UIModal for the floating center type
.floatingCenter {
  height: auto;
  left: 16px;
  right: 16px;
  bottom: 0;
  background-color: var(--ion-color-white);
  border-radius: 20px;
}

// Styles given to UIModal for the drawer type
.drawer {
  height: auto;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--ion-color-white);
  border-radius: 20px 20px 0 0;
}

.root {
  width: 100%;
  height: 100%;
}
