//This CSS file is derived from the toastify css styles, but trimmed down and modified to fit our needs.
// How far the toast appears from the top of the screen.
// Global change requested by design: https://nianticlabs.slack.com/archives/C023DMH2FPT/p1665174424333939
$toast-offset-from-top: 44px;
// Margin to apply to the toastify-toast container. Named aptly to avoid confusion with the toast-container.
$toastify-toast-margin: calc(#{$toast-offset-from-top} + var(--ion-safe-area-top)) 0 0 0;

:global(.Toastify) {
  //Needed to show the toast above ionic components
  z-index: 99999;
}

:global(.Toastify__toast-container) {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  left: 0;
  margin: 0;
}

:global(.Toastify__toast) {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  padding: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
  margin-bottom: 0;
  border-radius: 0;
  z-index: 10;

  //Necessary to prevent an error with preventDefault usage in toastify
  touch-action: none;
}
:global(.Toastify__toast--default) {
  background: #fff;
  margin: calc(#{$toast-offset-from-top} + var(--ion-safe-area-top)) 12px 0 12px;
  border-radius: 16px;

  &:global(.Toastify__toast-theme--dark) {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
    margin: $toastify-toast-margin;
    width: 85vw;
    max-width: 400px;
    min-height: 52px;
    padding: 4px 16px;

    // No progressbar when in dark mode.
    // Also, I can't believe it requires the progress bar to be rendered for autoClose to work??
    // https://github.com/fkhadra/react-toastify/issues/320
    :global(.Toastify__progress-bar) {
      visibility: hidden !important;
    }
  }
}
:global(.Toastify__toast--success) {
  background: var(--ion-color-success);
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(3, 206, 118, 0.2);
  margin: $toastify-toast-margin;
  width: 240px;
  min-height: 48px;
  text-align: center;
  padding: 4px;
}
:global(.Toastify__toast--error) {
  background: var(--ion-color-danger);
  box-shadow: 0 10px 20px 0 #fe2c2533;
  border-radius: 12px;
  margin: $toastify-toast-margin;
  width: 240px;
  min-height: 48px;
  text-align: center;
  padding: 4px;
}
:global(.Toastify__toast-body) {
  margin: auto 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 6px;
}

:global(.Toastify--animate) {
  animation-fill-mode: both;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
:global(.Toastify__progress-bar) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 9999;
  opacity: 0.5;
  transform-origin: left;
}
:global(.Toastify__progress-bar--animated) {
  animation: Toastify__trackProgress linear 1 forwards;
}
:global(.Toastify__progress-bar--controlled) {
  transition: transform 0.2s;
}
:global(.Toastify__progress-bar--rtl) {
  right: 0;
  left: initial;
  transform-origin: right;
}
:global(.Toastify__progress-bar--default) {
  background: #16bcf0;
}
:global(.Toastify__progress-bar--dark) {
  background: #bb86fc;
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, calc(-100% - #{$toast-offset-from-top}), 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, calc(-100% - #{$toast-offset-from-top}), 0);
  }
}

:global(.Toastify__slide-enter--top-center) {
  animation-name: Toastify__slideInDown;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0, 1, 0.55, 1);
}
:global(.Toastify__slide-exit--top-center) {
  animation-name: Toastify__slideOutUp;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0, 1, 0.55, 1);
}

/*# sourceMappingURL=ReactToastify.css.map */
