.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  height: 18px;

  &:active {
    background-color: rgba(75, 94, 120, 0.08);
  }
}

.logo {
  height: 18px;
  width: 18px;
  border-radius: 4px;
  overflow: hidden;
}

.gameName {
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
