.radioItem {
  border-bottom: 1px solid rgba(75, 94, 120, 0.08);
  padding: 10px 16px;

  --inner-border-width: 0px;

  ion-radio {
    width: 24px;
    height: 24px;
  }
}

.radioItem:last-child {
  border-bottom: 0px;
  --inner-border-width: 0px;
}

.ionItem {
  --padding-start: 0px;
  --inner-padding-end: 0px;
}
