// Shared styles for different menu item types to display separator lines between them even
// if multiple types are blended together.
.menuItem + .menuItem {
  &.connected {
    border-top: 1px solid rgba(75, 94, 120, 0.15);
  }

  &.disconnected {
    margin-top: 8px;
    border-top: 0;
  }
}
