.root {
  // This is the root of the Magellan experience rendered within a webview that's styled
  // like a "widget". The webview is set to be transparent so we have full control over the styling.
  // The contents should take up the full height of the widget viewport.
  box-sizing: border-box;
  height: 100%;
  // We want the contents to appear similar to a dialog so give it rounded corners and hide anything that overflows.
  border-radius: 15px;
  overflow: hidden;
  // All children should be relative to this root container so that the above styling applies at the highest level.
  position: relative;
}
