.root {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  padding: 0 24px 24px;
  box-sizing: border-box;
}

.services {
  display: flex;
  align-items: center;
}

.serviceName {
  flex: 1;
}

.serviceToggle {
  ion-icon {
    font-size: 32px;
    color: var(--ion-color-medium);

    &.success {
      color: var(--ion-color-success);
    }
  }
}

.enableBtn {
  margin-top: auto;
}
