// All action sheets should look like the system and not "Gilroy", our main font.
ion-action-sheet {
  --ion-font-family: var(--ion-default-font);
}

ion-picker {
  --ion-font-family: var(--ion-default-font);
}

// Capacitor camera still hasn't fixed the issue of the input not being hidden.
// https://github.com/ionic-team/capacitor-plugins/issues/259
:global(input#_capacitor-camera-input) {
  display: none !important;
}

// Addresses the popover Chrome display issue described here: https://github.com/ionic-team/ionic-framework/issues/27599
:global([popover].popover-viewport) {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}
