.root {
}

.appDisplayParent {
  display: flex;
  justify-content: center;

  > .logo:not(:last-child) {
    margin-right: 15px;
  }
}

.logo {
  height: 48px;
  width: 48px;
  border-radius: 12px;
}

.extraAppIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-light-shade);
}
