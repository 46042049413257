$core-navigation-full-surface-layout-fab-px-from-side: 20px;
$core-navigation-full-surface-layout-fab-px-from-bottom: 42px;
$core-navigation-full-surface-layout-calc-expression-px-from-bottom: var(
  --c-position-bottom-px-of-floating-tab-bar
);

.root {
  // Draw a surface that covers the parent stacking context.
  // Offset that surface offscreen 100% so it cannot be seen.
  // Shift individual elements back onscreen with the .shifted class.
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  // DEBUG: Comment this in to build the bottom content more easily.
  //bottom: unset !important;
  //background: red;
  //opacity: 0.7;

  // So that content in this container starts at the bottom, column-reverse.
  display: flex;
  flex-direction: column-reverse;

  z-index: var(--c-navigation-tabs-z-index);
}

.floatingTabBarCtn {
  display: flex;
}

.floatingTabBarContentCtn {
  position: absolute;
  bottom: calc(#{$core-navigation-full-surface-layout-calc-expression-px-from-bottom});
  left: $core-navigation-full-surface-layout-fab-px-from-side;
  right: $core-navigation-full-surface-layout-fab-px-from-side;

  &.shifted {
    // For Android Chrome < 79, since CSS Max() is not supported, ensure we position this somewhere decent.
    bottom: calc(100% + 42px);

    // Take the max between:
    // 1. SafeAreaBottom + FAB offset
    // 2. 42px from bottom
    // This way, we always offset the FAB's at least 42px off the bottom of the content, but if the safe area is bigger,
    // we will honor it.
    // NOTE: Capital-case "Max()" is used to avoid a Sass error.
    bottom: Max(
      calc(100% + #{$core-navigation-full-surface-layout-calc-expression-px-from-bottom}),
      calc(100% + #{$core-navigation-full-surface-layout-fab-px-from-bottom})
    );
    // DEBUG: Comment this in to build the bottom content more easily.
    //bottom: calc(#{$core-navigation-full-surface-layout-calc-expression-px-from-bottom}) !important;
  }
}
