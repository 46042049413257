.root {
  position: relative;
  display: inline-block;
}

.fill {
  position: absolute;
  // To lessen the "bleed" from our filling strategy since we can't adjust the shadow DOM in the IonIcon
  top: 1px;
  left: 1px;
}

.outline {
  position: absolute;
  top: 0;
  left: 0;
}

.online {
  color: var(--ion-color-success-shade);
}

.offline {
  color: var(--ion-color-medium-shade);
}
