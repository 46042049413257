.root {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;

  &:active {
    // Gives a "whiter" look when pressed, without making the icon transparent. Otherwise, you see the content behind.
    .icon {
      background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
        url("assets/icons/pgo_exit_button.svg");
    }
  }
}

// Opted for a simple svg approach since drawing the icon with pure HTML/CSS just doesn't seem
// worth it. But a cool exercise!
.icon {
  height: 100%;
  width: 100%;
  background-image: url("assets/icons/pgo_exit_button.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
}
