.bgContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.bgImage {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.scrollable {
  height: 100%;
  overflow: hidden auto;
}

.wizard {
  min-height: 600px;
}

.wizard .swiper-slide img {
  height: unset;
}
