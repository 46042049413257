.interceptPointerDuringAnimation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // Needs to beat the ion-header z-index of 10.
  z-index: 11;
  background-color: transparent;
}
