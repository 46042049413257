.content {
  text-align: center;
  padding: 32px 20px 20px;
  height: 100%;
}

.accountInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 20px;
}

.privacyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 28px;
}
