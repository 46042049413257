$core-navigation-floating-tab-bar-icon-size-px: 28px;
$core-navigation-floating-tab-bar-padding: 10px;
$core-navigation-floating-tab-bar-slide-up-px: 16px;

.root {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--c-app-tab-bar-v2-background-color);
  border-radius: 15px;
  height: var(--c-floating-tab-bar-height);
}

.popperContentRoot {
  width: 100%;
  height: 100%;
  padding: $core-navigation-floating-tab-bar-padding;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1;
}

.right {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.link {
  display: flex;
  position: relative;
}

.avatarCtn {
  border-radius: 50%;
  border: 2px solid transparent;
  display: inline-flex;

  &.selected {
    border: 2px solid #ffffff;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.iconCtn {
  display: grid;
  place-items: center;
  height: $core-navigation-floating-tab-bar-icon-size-px;
  width: $core-navigation-floating-tab-bar-icon-size-px;
  color: var(--ion-color-white);
}

.icon {
  width: 22px;
  height: 22px;

  &.notifications {
    &.selected {
      width: 20px;
      height: 19px;
    }
  }
}

.hasUnreadDot {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 7px;
  height: 7px;
  background-color: var(--ion-color-danger);
  border: 2px solid var(--c-app-tab-bar-v2-background-color);
  border-radius: 50%;
}

.realityChannelIconRedDotContainer {
  position: relative;
}

// Slightly different styling for the reality channel red dot
.hasUnreadDotRC {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 7px;
  height: 7px;
  background-color: var(--ion-color-danger);
  border: 2px solid var(--c-app-tab-bar-v2-background-color);
  border-radius: 50%;
}

// Reality Channel Selector
.realityChannelIcon {
  border-radius: 10px;
  height: 38px;
  width: 38px;
  overflow: hidden;
  flex: 0 0 auto;

  .realityChannelImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.realityChannelName {
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: 150px;
  // Make the tap region a bit bigger
  min-height: 36px;

  // For long names like NBA ALL WORLD (lol), change the font-size down. Short names like PGO are probably fine.
  .responsiveLongName {
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
}

.arrow {
  transition: transform 0.2s;
  transform: rotate(0deg) translateY(1px);
  flex: 0 0 auto;

  &.open {
    transform: rotate(180deg);
  }
}

// Reality Channel Dropdown Menu
// Fade in/out animation + Frosted glass effect + Slide up/down animation
.dropdownMenu {
  padding: 24px 16px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(17px);
  color: var(--ion-color-white);
  transition: transform 0.2s;
  animation: slideDown 0.2s;
  transform: translateY(10px);
  max-height: 245px;
  overflow: auto;

  &.open {
    animation: slideUp 0.2s;
    transform: translateY(0px);
  }
}

.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.popper {
  z-index: 2;
}

.linkOnRcName {
  min-width: 0;
}

@keyframes slideUp {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(10px);
  }
}
