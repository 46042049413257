.root {
  display: flex;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px 20px;
  width: 100%;
}

.closeBtn {
  margin: 28px 0 0 0;

  &::part(native) {
    background-color: #e4e7eb;
    border-radius: 24px;
    height: 48px;
    width: 142px;
  }
}
