.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-white);
  border-radius: 8px;
  margin: 0 20px;
  padding: 16px;
  height: auto;
}

.iconContainer {
  flex-shrink: 0;
  width: 64px;
  margin: auto;
}

.bodyContainer {
  display: flex;
  width: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 0 auto;
  flex-shrink: 0;
  height: 32px;
  --padding-start: 28px;
  --padding-end: 28px;
  --border-radius: 14px;
}
