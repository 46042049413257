.userListItem {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.endSlot {
  margin: 0;
}

.itemParent {
  padding-bottom: var(--ion-safe-area-bottom);

  > .ionItem + .ionItem {
    border-top: 1px solid rgba(75, 94, 120, 0.08);
  }
}

.ionItem {
  --padding-start: 10px;
  --padding-end: 10px;
}
