.contentRoot {
  --overflow: hidden;
}

.pageRoot {
  // Maintaining scroll position when returning to a chat view:
  // ==========================================================
  // For the ClubChannel tab, Ionic's default display: none !important when the tab is hidden causes the browser to reflow,
  // and this jacks with the scroll position of any scroll view in that tab upon reflowing.
  // I played with a version of this that tracks the last known scroll position and uses an IntersectionObserver
  // to detect when to trigger a scroll restoration, however, this is much simpler and I wonder if it actually
  // performs slightly better when swapping tabs since it doesnt need to reflow the chat messages.
  &:global(.ion-page-hidden) {
    display: block !important;
    visibility: hidden;
    z-index: 0 !important;
  }
}

.root {
  height: 100%;
  width: 100%;

  &.campfireV2 {
    padding-bottom: calc(var(--ion-safe-area-bottom));
    box-sizing: border-box;
  }
}
