.root {
  padding: 28px 20px;
  text-align: center;
}

.btnContainer {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.btn {
  flex: 1;
}
