@import "~common/css/mixins/table.scss";

.root {
  padding: 32px 40px;
  text-align: center;
  background: var(--background);
  border-radius: 20px;
}

.featureList {
  @include generateTableRoot();
  display: inline-grid;
  text-align: left;
  padding: 16px 6px 4px 6px;

  .tableRow {
    @include generateTableRow(50px 1fr, false, 8px 0);
  }

  .tableCell {
    @include generateTableCell();
    padding: 0;
  }
}

.icon {
  color: var(--ion-color-primary);
  object-fit: contain;
  max-width: 35px;
  max-height: 35px;
}
