.root {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  padding: 0 24px 24px;
  box-sizing: border-box;
}

.campfireLogo {
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.okButton {
  margin-top: auto;
}
