.appSpinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
}

.fullScreen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.fullHeight {
  height: 100%;
}
