.root {
  position: relative;
  height: 100%;
  background-image: url("assets/images/backgrounds/landing_page_no_tree.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 75%;
}

.campfireLogo {
  height: 72px;
  width: 72px;
  margin: 0 auto;
}

.info {
  position: absolute;
  top: 20%;
  left: 24px;
  right: 24px;
  margin: 0 auto;
  text-align: center;
}
