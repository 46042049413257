// The entire onboarding flow will anchor from this root. Layering can be controlled within the flow here.
.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(183, 183, 183, 0.8);
  z-index: 1;
}

.wizardCardCtn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 110px;
  z-index: 20;
}
