// Set a high z-index on the modal to ensure it's placed on top of anything else.
// Then set modal 1 higher to ensure it's clickable to dismiss
// Then place the child div so the modal content is placed on top of the root and backdrop divs
$ui-modal-root-z-index: 2000;
$ui-modal-backdrop-z-index: 2001;
$ui-modal-children-ctn-z-index: 2002;

.root {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $ui-modal-root-z-index;
  contain: size layout style;
}

.backdropCtn {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $ui-modal-backdrop-z-index;
}

.childCtn {
  z-index: $ui-modal-children-ctn-z-index;
  position: absolute;
}

.childCtnFullscreen {
  height: 100%;
  width: 100%;
}
